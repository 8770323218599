:root {
    --typo-controls-font-family: Ubuntu, sans-serif;
    --typo-controls-size-s-font-size: 1rem;
    --typo-controls-size-m-font-size: 1rem;
    --tumbler-font-family: var(--typo-controls-font-family);
    --tumbler-button-space: 2px;
    --tumbler-button-opacity-disabled: 1;
    --tumbler-size-s-fontSize: 1rem; /* Размер текста s-tumbler */
    --tumbler-size-s-lineHeight: 16px; /* Межстрочный интервал текста у s-tumbler */
    --tumbler-size-s-borderRadius: 8px;
    --tumbler-size-s-width: 32px; /* Ширина s-tumbler */
    --tumbler-size-s-height: 16px; /* Высота s-tumbler */
    --tumbler-size-s-button-borderRadius: 6px;
    --tumbler-size-s-button-width: 12px; /* Ширина button m-tumbler */
    --tumbler-size-s-button-height: 12px; /* Высота button m-tumbler */
    --tumbler-size-s-button-press-width: 14px;
    --tumblerLabel-size-s-indentRight: 8px;
    --tumblerLabel-size-s-indentLeft: 8px;
    --tumbler-size-m-fontSize: 1rem; /* Размер текста m-tumbler */
    --tumbler-size-m-lineHeight: 20px; /* Межстрочный интервал текста у m-tumbler */
    --tumbler-size-m-borderRadius: 10px;
    --tumbler-size-m-width: 40px; /* Ширина m-tumbler */
    --tumbler-size-m-height: 20px; /* Высота m-tumbler */
    --tumbler-size-m-button-borderRadius: 8px;
    --tumbler-size-m-button-width: 16px; /* Ширина button m-tumbler */
    --tumbler-size-m-button-height: 16px; /* Высота button m-tumbler */
    --tumbler-size-m-button-press-width: 18px;
    --tumblerLabel-size-m-indentRight: 12px;
    --tumblerLabel-size-m-indentLeft: 12px;
    --tumbler-size-l-fontSize: 1rem;
    --tumbler-size-l-lineHeight: 24px;
    --tumbler-size-l-borderRadius: 14px;
    --tumbler-size-l-width: 56px;
    --tumbler-size-l-height: 28px;
    --tumbler-size-l-button-borderRadius: 12px;
    --tumbler-size-l-button-width: 24px;
    --tumbler-size-l-button-height: 24px;
    --tumbler-size-l-button-press-width: 26px;
    --tumblerLabel-size-l-indentRight: 16px;
    --tumblerLabel-size-l-indentLeft: 16px;
    --spin-size-xxs: 16px;
    --spin-size-xs: 24px;
    --spin-size-s: 28px;
    --spin-size-m: 32px;
    --spin-size-l: 86px;
    --button-font-family: var(--typo-controls-font-family);
    --button-border-width: 1px;
    --button-borderRadius: 4px; /* Радиус внутреннего блока */
    --button-size-m-font: var(--typo-controls-size-m-font-size); /* Размер текста у m-button */
    --button-size-m-lineHeight: 36px; /* Межстрочный интервал текста у m-button */
    --button-size-m-height: 36px; /* Высота у m-button */
    --button-size-m-icon-width: 36px; /* Ширина иконки у m-button */
    --button-size-m-icon-indentLeft: 29px; /* Размер внешнего левого отступа текста от иконки у m-button */
    --button-size-m-icon-indentRight: 29px; /* Размер внешнего правого отступа текста от иконки у m-button */
    --button-size-m-text-indentAll: 0 15px; /* Размер внешнего отступа у m-button */
    --textarea-font-family: var(--typo-controls-font-family);
    --textarea-borderWidth: 2px;
    --textarea-borderRadius: 4px; /* Ширина бордера у textarea */
    --textarea-size-m-fontSize: var(--typo-controls-size-m-font-size); /* Размер текста у m textarea */
    --textarea-size-m-spaceAll: 8px; /* Внутренние отступы m-textarea */
    --textarea-size-m-minWidth: 200px; /* Размер минимальной ширины у m-textarea */
    --textarea-size-m-minHeight: 36px; /* Размер минимальной высоты у m-textarea */
    --textarea-size-m-clear-width: 36px; /* Ширина очищающего контрола у m-textarea */
    --textarea-size-m-clear-height: 36px; /* Высота очищающего контрола у m-textarea */
    --textarea-hint-size-m-fontSize: 13px;
}
