.feedback-submit-container {
    display: inline-flex;
}

.feedback-submit-connect-container {
    display: inline-flex;

    justify-content: center;
    align-items: center;
    text-align: center;

    padding-left: 25px;
}
