body {
  background-color: rgb(237,241,242);

  font-family: Ubuntu, sans-serif;
}

html, body, #root {
  height: 100%;
}

#feedback-container {
  max-width: 500px;

  padding: 20px 55px;
  font-size: 1rem;
}

.feedback-order-info {
  margin-bottom: 55px;
}

.feedback-header-container {
  margin-bottom: 25px;
}

#feedback-header {
  font-size: 2.4rem;
}

#feedback-header-desc {
  font-weight: 4000;
  font-size: 1rem;
}
