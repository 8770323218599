.feedback-rate-container {
    display: flex;
    flex-direction: row;

    align-items: center;
    margin-bottom: 25px;
}

.feedback-rate-desc {
    margin-left: 25px;
}

.invalid {
    color: var(--color-fill-color-alert);
}

.feedback-rate-item-container {
    display: inline-flex;
    flex-direction: row-reverse;
}

.feedback-rate-item-container > input {
    display:none;
}

.feedback-rate-item-container > label {
    cursor: pointer;
}

.feedback-rate-item-container > label svg,
.feedback-rate-item-container:hover > label svg {
    color: var(--rate-view-default-fill-color-base);
}

.feedback-rate-item-container > label:hover svg,
.feedback-rate-item-container > label:hover ~ label svg {
    color: var(--rate-view-default-fill-color-hovered);
}

.feedback-rate-item-container:not(:hover) > input:checked ~ label svg {
    color: var(--rate-view-default-fill-color-checked);
}