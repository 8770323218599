.feedback-comment-container {
    margin-bottom: 25px;
}

.feedback-comment-label {
    padding-bottom: 10px;
}

.feedback-comment-input textarea {
    width: 100%;
    padding: 5px 10px;

    font-size: 1rem;

    border: 0;
    resize: none;
}