:root {
    --color-base: #000; /* базовый цвет содержимого, от которого выстраиваются цвета текста, иконок, ... */
    --color-control-background: #fff;
    --color-essential: rgb(179, 188, 197);
    --color-project: rgb(34, 59, 86); /* проектный цвет, от которого выстраивают акцентные состояния */
    --color-phantom: #000; /* тонирующий цвет, от которого выстраиваются бордеры, паранджа, ... */
    --color-path: #04b; /* ссылочный цвет, от которого выстраиваются все их вариации */
    --color-success: #00985f; /* цвет успеха, от которого выстраивается как статусный фон так и типографика */
    --color-alert: #f33; /* цвет ошибки, от которого выстраивается как статусный фон так и типографика */
    --color-warning: #f90; /* цвет предупреждения, от которого выстраивается как статусный фон так и типографика */
    --color-normal: #dde3e5; /* нейтральный цвет, от которого выстраивается как статусный фон так и типографика */
    --color-system: #778a9b; /* системный цвет, от которого выстраивается как статусный фон так и типографика */
    --color-promo: #0057d3;
    --color-hover: #c00;
    --color-transparent: transparent;
    --color-fill-color-brand: var(--color-project);
    --color-fill-color-inverse: var(--color-base);
    --color-fill-color-action: rgb(57, 98, 142);
    --color-fill-color-selection: rgb(91, 140, 190);
    --color-fill-color-hover: rgba(0, 0, 0, 0.15);
    --color-fill-color-border: rgba(0, 0, 0, 0.05);
    --color-fill-color-stripe: rgba(0, 0, 0, 0.04);
    --color-fill-color-ghost: rgba(0, 0, 0, 0.08);
    --color-fill-color-default: var(--color-essential);
    --color-fill-color-tone: rgba(0, 0, 0, 0.9);
    --color-fill-color-soft: rgba(179, 188, 197, 0.97);
    --color-fill-color-success: var(--color-success);
    --color-fill-color-alert: var(--color-alert);
    --color-fill-color-warning: var(--color-warning);
    --color-fill-color-normal: var(--color-normal);
    --color-fill-color-system: var(--color-system);
    --color-fill-color-link: rgba(21, 126, 239, 0.4);
    --color-fill-color-promo: var(--color-promo);
    --color-typo-brand: var(--color-project);
    --color-typo-primary: var(--color-base); /* цвет основного текста дефолтных контролов */
    --color-typo-inverse: var(--color-essential);
    --color-typo-promo: rgba(0, 0, 0, 0.8);
    --color-typo-secondary: rgba(0, 0, 0, 0.6); /* цвет второстепенного текста контрола */
    --color-typo-ghost: rgba(0, 0, 0, 0.3); /* цвет дополнительного текста контрола */
    --color-typo-disable: rgba(0, 0, 0, 0.3); /* цвет основного текста неактивного контрола */
    --color-typo-success: var(--color-success);
    --color-typo-warning: var(--color-warning);
    --color-typo-alert: rgb(224, 0, 0);
    --color-link-base: var(--color-path); /* цвет фона ссылки */
    --color-link-external: var(--color-path);
    --color-link-minor: rgb(102, 102, 153);
    --color-link-hover: var(--color-hover); /* цвет фона ссылки по ховеру */
    --color-control-border-color-focus: rgb(179, 179, 179); /* цвет фона бордеров контрола при фокусе */
    --color-control-border-color-prism: var(--color-project); /* цвет фона бордера офсетных выделений контрола */
    --color-control-typo-primary: var(--color-base);
    --color-control-typo-secondary: rgba(0, 0, 0, 0.6);
    --color-control-typo-ghost: rgba(0, 0, 0, 0.3);
    --color-control-typo-passive: var(--color-control-background);
    --color-control-typo-faint: rgb(255, 255, 255);
    --color-control-typo-disable: rgb(128, 128, 128);
    --color-control-typo-link: var(--color-path);
    --color-control-typo-error: rgb(224, 0, 0);
    --color-control-type-default-fill-color-base: var(--color-control-background); /* цвет фона контрола */
    --color-control-type-default-fill-color-hover: rgba(34, 59, 86, 0.8); /* цвет фона контрола по ховеру */
    --color-control-type-default-fill-color-press: rgba(34, 59, 86, 0.8); /* цвет фона контрола по нажатию */
    --color-control-type-default-fill-color-disable: rgba(34, 59, 86, 0.9); /* цвет фона неактивного контрола */
    --color-control-type-default-border-color-base: rgb(179, 179, 179); /* цвет фона бордеров контрола */
    --color-control-type-default-border-color-hover: rgb(219, 219, 219); /* цвет фона бордеров контрола по ховеру */
    --color-control-type-default-border-color-press: rgb(179, 179, 179); /* цвет фона бордеров контрола по нажатию */
    --color-control-type-default-border-color-disable: rgb(232, 232, 232); /* цвет фона бордеров неактивного контрола */
    --color-control-type-default-border-color-error-base: var(--color-alert);
    --color-control-type-default-border-color-error-hover: rgb(224, 0, 15);
    --color-control-type-pseudo-fill-color-base: rgb(255, 255, 255); /* цвет фона псевдо контрола */
    --color-control-type-pseudo-fill-color-hover: rgb(247, 247, 247); /* цвет фона псевдо контрола по ховеру */
    --color-control-type-pseudo-border-color-base: rgb(204, 204, 204); /* цвет бордеров псевдо контрола */
    --color-control-type-action-fill-color-base: var(--color-project); /* цвет фона активного контрола */
    --color-control-type-action-fill-color-hover: rgb(33, 59, 84); /* цвет фона активного контрола по ховеру */
    --color-control-type-action-fill-color-press: rgb(33, 59, 84); /* цвет фон активного контролы по нажатию */
    --color-control-type-passive-fill-color-base: var(--color-essential); /* цвет фона пассивной части контрола */
    --color-control-type-progress-fill-color-base: var(--color-project); /* цвет фон активного контрола в прогрессе */
    --color-control-type-progress-fill-color-process: rgb(35, 59, 88); /* цвет фон активного контрола в прогрессе выполнения */
    --color-control-type-blank-fill-color-base: var(--color-control-background); /* цвет фона пустого контрола */
    --color-control-type-blank-fill-color-hover: var(--color-control-background); /* цвет фона пустого контрола по ховеру */
    --color-control-type-blank-fill-color-disable: rgb(242, 242, 242); /* цвет фона пустого неактивного контрола */
    --color-control-type-blank-border-color-base: rgb(217, 217, 217); /* цвет фона бордеров пустого контрола */
    --color-control-type-blank-border-color-hover: rgb(204, 204, 204); /* цвет фона бордеров пустого контрола по ховеру */
    --color-control-type-blank-border-color-disable: rgb(241, 242, 244); /* цвет фона бордеров пустого неактивного контрола */
    --color-control-type-faint-fill-color-base: var(--color-essential); /* цвет фона ослабленной части контрола */
    --color-control-type-faint-fill-color-disable: rgb(192, 199, 206); /* цвет фона ослабленной части неактивного контрола */
    --color-control-type-check-fill-color-base: rgb(84, 84, 84); /* цвет фона чекнутого контрола */
    --color-control-type-check-fill-color-hover: rgb(84, 84, 84); /* цвет фона чекнутого контрола по ховеру */
    --color-control-type-check-fill-color-press: rgb(82, 82, 82); /* цвет фона чекнутого контрола по нажатию */
    --color-control-type-check-fill-color-disable: rgb(242, 242, 242); /* цвет фона чекнутого неактивного контрола */
    --color-control-type-check-border-color-base: rgb(48, 83, 120); /* цвет фона бордеров чекнутого контрола */
    --color-control-type-check-border-color-hover: rgb(48, 83, 120); /* цвет фона бордеров чекнутого контрола по ховеру */
    --color-control-type-check-border-color-press: rgb(48, 83, 120); /* цвет фона бордеров чекнутого контрола при фокусе по нажатию */
    --color-control-type-link-fill-color-base: var(--color-path);
    --color-control-type-link-fill-color-hover: rgba(0, 68, 187, 0.3);
    --color-control-type-link-border-color-base: rgba(0, 68, 187, 0.6);
    --color-control-type-link-border-color-hover: rgba(0, 68, 187, 0.6);
    --color-control-type-raised-typo-base: #000;
    --color-control-type-raised-typo-disable: #7f7f7f;
    --color-control-type-raised-fill-color-base: #fff;
    --color-control-type-raised-fill-color-hover: #f5f5f5;
    --color-control-type-raised-fill-color-disable: #f5f5f5;
    --color-control-type-raised-fill-color-check: #fff;
    --tumbler-view-default-outline-color: var(--color-control-border-color-prism);
    --tumbler-view-default-fill-color-base: var(--color-control-type-default-fill-color-base);
    --tumbler-view-default-fill-color-disabled: var(--color-control-type-default-fill-color-disable);
    --tumbler-view-default-fill-color-hovered: var(--color-control-type-default-fill-color-hover);
    --tumbler-view-default-fill-color-checked-base: var(--color-control-type-action-fill-color-base);
    --tumbler-view-default-fill-color-checked-disabled: var(--color-control-type-default-fill-color-disable);
    --tumbler-view-default-fill-color-checked-hovered: var(--color-control-type-action-fill-color-hover);
    --tumbler-view-default-button-fill-color-base: var(--color-control-type-faint-fill-color-base);
    --tumbler-view-default-typo-color-base: var(--color-control-typo-primary);
    --tumbler-view-default-typo-color-disabled: var(--color-control-typo-disable);
    --rate-view-default-fill-color-base: var(--color-control-type-faint-fill-color-base);
    --rate-view-default-fill-color-hovered: var(--color-control-type-default-fill-color-hover);
    --rate-view-default-fill-color-checked: var(--color-control-type-action-fill-color-base);
    --spin-view-default-border-color: var(--color-control-type-progress-fill-color-base);
    --button-view-action-border-color-focused: var(--color-control-border-color-prism);
    --button-view-action-fill-color-base: var(--color-control-type-action-fill-color-base);
    --button-view-action-fill-color-disabled: var(--color-control-type-default-fill-color-disable);
    --button-view-action-fill-color-hovered: var(--color-control-type-action-fill-color-hover);
    --button-view-action-fill-color-progress-base: var(--color-control-type-progress-fill-color-base);
    --button-view-action-fill-color-progress-process: var(--color-control-type-progress-fill-color-process);
    --button-view-action-fill-color-checked-base: var(--color-control-type-check-fill-color-base);
    --button-view-action-fill-color-checked-hovered: var(--color-control-type-check-fill-color-hover);
    --button-view-action-typo-color-base: var(--color-control-typo-passive);
    --button-view-action-typo-color-disabled: var(--color-control-typo-disable);
    --button-view-action-typo-color-hovered: var(--color-control-typo-passive);
    --button-view-action-typo-color-progress-base: var(--color-control-typo-passive);
    --button-view-action-typo-color-checked-base: var(--color-control-typo-faint);
    --button-view-action-typo-color-checked-hovered: var(--color-control-typo-faint);
    --textarea-view-default-fill-color-base: var(--color-control-type-blank-fill-color-base);
    --textarea-view-default-fill-color-disabled: var(--color-control-type-blank-fill-color-disable);
    --textarea-view-default-fill-color-hovered: var(--color-control-type-blank-fill-color-hover);
    --textarea-view-default-typo-color-base: var(--color-control-typo-primary);
    --textarea-view-default-typo-color-disabled: var(--color-control-typo-disable);
    --textarea-view-default-placeholder-typo-color-base: var(--color-control-typo-ghost);
    --textarea-view-default-placeholder-typo-color-disabled: var(--color-control-typo-disable);
    --textarea-view-default-icon-fill-color-base: var(--color-control-typo-ghost);
    --textarea-view-default-clear-fill-color-base: var(--color-control-typo-ghost);
    --textarea-view-default-clear-fill-color-hovered: var(--color-control-typo-primary);
    --textarea-view-default-hint-typo-color-state-error-base: var(--color-control-typo-error);
    --textarea-view-default-border-color-base: var(--color-control-type-blank-border-color-base);
    --textarea-view-default-border-color-disabled: var(--color-control-type-blank-border-color-disable);
    --textarea-view-default-border-color-focused: var(--color-control-border-color-focus);
    --textarea-view-default-border-color-hovered: var(--color-control-type-blank-border-color-hover);
    --textarea-view-default-border-color-state-error-base: var(--color-control-type-default-border-color-error-base);
    --textarea-view-default-border-color-state-error-hovered: var(--color-control-type-default-border-color-error-hover);
}
